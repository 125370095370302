<app-menu></app-menu>
<div class="realisation-detail-container">
    <div class="realisation-image-container">
        <div class="image-overlay">
            <a routerLink="/" class="back-link">
                <fa-icon class="back-link-icon" [icon]="faChevronLeft"></fa-icon>{{"MENU.BACK" | translate}}
            </a>
            <div class="overlay-text">{{"MENU.REALISATIONS" | translate}}</div>
        </div>
    </div>
</div>

<div class="submenu">
    <a (click)="filterProjects('all')" [class.active]="currentFilter === 'all'" class="submenu-link">{{ translatedTypes.all }}</a>
    <a (click)="filterProjects(translatedTypes.fluvial)" [class.active]="currentFilter === translatedTypes.fluvial" class="submenu-link">{{ "PROJECT_TYPES.fluvial" | translate }}</a>
    <a (click)="filterProjects(translatedTypes.maritime)" [class.active]="currentFilter === translatedTypes.maritime" class="submenu-link">{{ "PROJECT_TYPES.maritime" | translate }}</a>
    <a (click)="filterProjects(translatedTypes.grand)" [class.active]="currentFilter === translatedTypes.grand" class="submenu-link">{{ "PROJECT_TYPES.grand" | translate }}</a>
</div>
<div class="projects-section">
    <div class="projects-container gap-4">
        <div class="project-box" *ngFor="let project of filteredProjects">
            <div class="project-info gap-1">
                <div class="project-type">{{ project.type }}</div>
                <div class="project-title">{{ getTruncatedTitle(project.title, 30) }}</div>
                <div class="project-details">
                    <div class="subtitle">{{ "PROJECT.LOCATION" | translate }}<span class="subtitle-text">{{ project.city }} - {{ project.country }}</span></div>
                </div>
            </div>
            <div class="project-image-container">
                <img [src]="'/assets/' + project.imageUrl" class="project-image">
                <div class="project-overlay"></div>
                <button class="project-details-button" (click)="selectProject(project)">
                    <fa-icon class="project-details-button-icon" [icon]="faChevronRight"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
