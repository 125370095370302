<img src="../../assets/contact-image.png" class="contact-container">
<div class="d-flex flex-row contact">
        <div class="d-flex flex-row gap-3">
            <div class="contact-spacing d-flex flex-column">
                <div class="contact-title">{{"CONTACT.TITLE" |translate}}</div>
                <div class="contact-subtitle">{{"CONTACT.SUBTITLE" |translate}}</div>
                <div class="d-flex flex-row gap-2 icons">
                    <fa-icon [icon]="faLocationDot"></fa-icon>
                    <div class="contact-subtitle">115 rue François de Sourdis, 33 000 Bordeaux</div>
                </div>
                <div class="d-flex flex-row gap-2 icons">
                    <fa-icon [icon]="faPhone"></fa-icon>
                    <div class="contact-subtitle">+330557351900</div>
                </div>
            </div>
            <form (ngSubmit)="onSubmit()" [formGroup]="contactForm">
                <div>
                    <input type="text" formControlName="nom" name="name" required [placeholder]="'CONTACT.NAME' | translate">
                </div>
                <div>
                    <input type="email" formControlName="email" name="email" required [placeholder]="'CONTACT.EMAIL' | translate">
                </div>
                <div class="select-size">
                    <select id="country" class="form-select d-flex w-100 select-padding" formControlName="country" >
                        <option value="" disabled selected>{{"CONTACT.COUNTRY" | translate}}</option>
                        <option *ngFor="let country of countries" [value]="country">{{ country }}</option>
                    </select>
                </div>
                <div>
                    <input type="text" formControlName="objet" name="objet" required [placeholder]="'CONTACT.OBJECT' | translate">
                </div>
                <div>
                    <textarea formControlName="message" name="message" required [placeholder]="'CONTACT.MESSAGE' | translate" rows="4"></textarea>
                </div>
                <button type="submit" class="home-button" [matTooltip]="isFormValid() ? '' : 'Veuillez remplir le formulaire d\'abord'" [disabled]="contactForm.invalid">{{"CONTACT.BUTTON" | translate}}</button>
            </form>
        </div>
    </div>

