import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import {BehaviorSubject, Observable, Subject} from "rxjs";

@Injectable({
    providedIn: 'root'
})
export class ProjectService {
    private projects: any[] = [];
    private projectsSubject = new BehaviorSubject<any[]>([]);
    private filteredProjects: any[] = [];

    constructor(private translate: TranslateService) {
        this.loadProjects();
        this.translate.onLangChange.subscribe(() => {
            this.loadProjects();
        });
    }

    public loadProjects(): void {
        const translations = this.translate.instant('PROJECTS');
        this.projects = Array.isArray(translations) ? translations : [];
        this.filteredProjects = [...this.projects];
        this.projectsSubject.next(this.filteredProjects);
    }

    getProjects(): Observable<any[]> {
        return this.projectsSubject.asObservable();
    }

    filterProjects(type: string): void {
        if (type === 'all') {
            this.filteredProjects = [...this.projects];
        } else {
            this.filteredProjects = this.projects.filter(project => project.type === type);
        }
        this.projectsSubject.next(this.filteredProjects);
    }

    getProjectById(id: number): any {
        return this.projects.find(project => project.id === id);
    }
}
