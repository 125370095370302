<div class="d-flex flex-column align-items-center">
    <div class="service-title mb-2">{{"SERVICES.TITLE" | translate}}</div>
    <div class="service-subtitle mb-2">{{"SERVICES.SUBTITLE" | translate}}</div>
    <div class="service-container">
        <img src="../../assets/service-image.png">
    </div>
    <div class="realisations-container">
        <div class="image-box">
            <img src="assets/projets_fluviaux.png">
            <div class="image-overlay"></div>
            <div class="title">{{"SERVICES.SECTION-TITLE" | translate}}</div>
            <div class="description">
                <div class="description-title">{{"SERVICES.PROJECT-TITLE" | translate}}</div>
                <div>{{"SERVICES.PROJECT-DESCRIPTION" | translate}}</div>
                <a routerLink="/realisations" class="link">{{"SERVICES.LINK" | translate}}</a>
            </div>
        </div>
        <div class="image-box">
            <img src="assets/projet_maritimes.png">
            <div class="image-overlay"></div>
            <div class="title">{{"SERVICES.SECTION-TITLE-2" | translate}}</div>
            <div class="description">
                <div class="description-title">{{"SERVICES.SECTION-TITLE-2" | translate}}</div>
                <div>{{"SERVICES.PROJECT-DESCRIPTION-2" | translate}}</div>
                <a routerLink="/realisations" class="link">{{"SERVICES.LINK" | translate}}</a>
            </div>
        </div>
        <div class="image-box">
            <img src="assets/projets_grands.png">
            <div class="image-overlay"></div>
            <div class="title">{{"SERVICES.SECTION-TITLE-3" | translate}}</div>
            <div class="description">
                <div class="description-title">{{"SERVICES.SECTION-TITLE-3" | translate}}</div>
                <div>{{"SERVICES.PROJECT-DESCRIPTION-3" | translate}}</div>
                <a routerLink="/realisations" class="link">{{"SERVICES.LINK" | translate}}</a>
            </div>
        </div>
    </div>
    <div>
        <a class="service-button mb-2" routerLink="/realisations">{{"SERVICES.LINK-2" | translate}}</a>
    </div>
</div>
