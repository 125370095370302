<!--<nav class="d-flex justify-content-between">-->
<!--    <div class="logo" (click)="goToHome()">-->
<!--        <img class="logo" src="assets/logo.png">-->
<!--    </div>-->
<!--    <ul>-->
<!--        <li>-->
<!--            <a-->
<!--                class="nav-link"-->
<!--                [routerLink]="['']"-->
<!--                [class.active]="activeLink === 'home'"-->
<!--                (click)="setActiveLink('home')"-->
<!--            >-->
<!--                {{ 'MENU.HOME' | translate }}-->
<!--            </a>-->
<!--        </li>-->
<!--        <li>-->
<!--            <a-->
<!--                class="nav-link"-->
<!--                [routerLink]="['/realisations']"-->
<!--                [class.active]="activeLink === 'services'"-->
<!--                (click)="setActiveLink('services')"-->
<!--            >-->
<!--                {{ 'MENU.REALISATIONS' | translate }}-->
<!--            </a>-->
<!--        </li>-->
<!--        <li>-->
<!--            <a-->
<!--                class="nav-link"-->
<!--                [routerLink]="['/']"-->
<!--                fragment="contact"-->
<!--                [class.active]="activeLink === 'contact'"-->
<!--                (click)="setActiveLink('contact')"-->
<!--            >-->
<!--                {{ 'MENU.CONTACT' | translate }}-->
<!--            </a>-->
<!--        </li>-->
<!--        <li class="language-selector d-flex justify-content-center">-->
<!--            <select (change)="onLanguageChange($event)">-->
<!--                <option value="fr" [selected]="translate.currentLang === 'fr'">FR</option>-->
<!--                <option value="en" [selected]="translate.currentLang === 'en'">EN</option>-->
<!--            </select>-->
<!--        </li>-->
<!--    </ul>-->
<!--</nav>-->






<nav class="d-flex justify-content-between">
    <div class="logo" (click)="goToHome()">
        <img class="logo" src="assets/logo.png">
    </div>
    <ul>
        <li>
            <a
                class="nav-link"
                [routerLink]="['']"
                [class.active]="activeLink === 'home'"
                (click)="setActiveLink('home')"
            >
                {{ 'MENU.HOME' | translate }}
            </a>
        </li>
        <li>
            <a
                class="nav-link"
                [routerLink]="['/realisations']"
                [class.active]="activeLink === 'services'"
                (click)="setActiveLink('services')"
            >
                {{ 'MENU.REALISATIONS' | translate }}
            </a>
        </li>
        <li>
            <a
                class="nav-link"
                [routerLink]="['/']"
                fragment="contact"
                [class.active]="activeLink === 'contact'"
                (click)="scrollToContact()"
            >
                {{ 'MENU.CONTACT' | translate }}
            </a>
        </li>
        <li class="language-selector d-flex justify-content-center">
            <select (change)="onLanguageChange($event)">
                <option value="fr" [selected]="translate.currentLang === 'fr'">FR</option>
                <option value="en" [selected]="translate.currentLang === 'en'">EN</option>
            </select>
        </li>
    </ul>
</nav>
