<div class="d-flex flex-column align-items-center gap-3">
    <div class="project-title mb-2">{{ "PROJECT.TITLE" | translate }}</div>
    <div class="d-flex flex-row gap-4 realisations-container">
        <div class="projet-image-box" *ngFor="let project of projects | slice:0:1">
            <img [src]="'assets/' + project.imageUrl" class="w-100">
            <div class="projet-overlay"></div>
            <div class="project-info-first-box gap-1">
                <div class="project-type">{{ project.type }}</div>
                <div class="section-title">{{ project.title }}</div>
                <div class="subtitle">{{ "PROJECT.LOCATION" | translate }}<span class="subtitle-text">{{ project.city }} - {{ project.country }}</span></div>
            </div>
            <button class="project-button" (click)="selectProject(project)">
                <fa-icon class="project-button-icon" [icon]="faChevronRight"></fa-icon>
            </button>
        </div>
        <div class="d-flex flex-column gap-3">
            <div class="projet-image-box2" *ngFor="let project of projects | slice:1:3">
                <img [src]="'assets/' + project.imageUrl" class="w-100">
                <div class="projet-overlay"></div>
                <div class="project-info gap-1">
                    <div class="project-type">{{ project.type }}</div>
                    <div class="section-title">{{ project.title }}</div>
                    <div class="subtitle">{{ "PROJECT.LOCATION" | translate }}<span class="subtitle-text">{{ project.city }} - {{ project.country }}</span></div>
                </div>
                <button class="project-button" (click)="selectProject(project)">
                    <fa-icon class="project-button-icon" [icon]="faChevronRight"></fa-icon>
                </button>
            </div>
        </div>
    </div>
</div>
