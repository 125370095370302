import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { ScrollService } from './scroll.service';

import { AppComponent } from './app.component';
import {HomeComponent} from "./home/home.component";
import {ServicesComponent} from "./services/services.component";
import {ContactComponent} from "./contact/contact.component";
import {MenuComponent} from "./menu/menu.component";
import {FooterComponent} from "./footer/footer.component";
import {ReactiveFormsModule} from "@angular/forms";
import {RealisationsComponent} from "./realisations/realisations.component";
import {AppRoutingModule} from "./app-routing.module";
import {RealisedProjectsComponent} from "./realised-projects/realised-projects.component";
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import {ProjetDetailComponent} from "./projet-detail/projet-detail.component";
import {HttpClient, HttpClientModule} from "@angular/common/http";
import {TranslateLoader, TranslateModule} from "@ngx-translate/core";
import {TranslateHttpLoader} from "@ngx-translate/http-loader";
import {ProjectService} from "./project.service";
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import {MatTooltipModule} from "@angular/material/tooltip";

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    ServicesComponent,
    ContactComponent,
    MenuComponent,
    FooterComponent,
    RealisationsComponent,
    RealisedProjectsComponent,
    ProjetDetailComponent

  ],
    imports: [
        BrowserModule,
        ReactiveFormsModule,
        AppRoutingModule,
        FontAwesomeModule,
        MatTooltipModule,
        HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient]
            }
        })
    ],
  providers: [ProjectService, provideAnimationsAsync()],
  bootstrap: [AppComponent]
})
export class AppModule { }
