import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { faChevronLeft, faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { ProjectService } from '../project.service';

@Component({
    selector: 'app-projet-detail',
    templateUrl: './projet-detail.component.html',
    styleUrls: ['./projet-detail.component.scss']
})
export class ProjetDetailComponent implements OnInit {
    faChevronLeft = faChevronLeft;
    faArrowRight = faArrowRight;
    project: any;

    constructor(private route: ActivatedRoute, private projectService: ProjectService, private router: Router) {}

    ngOnInit(): void {
        const projectIdString = this.route.snapshot.paramMap.get('id');
        if (projectIdString) {
            const projectId = +projectIdString;
            this.project = this.projectService.getProjectById(projectId);

            // Si le projet est introuvable, redirigez vers la page d'accueil
            if (!this.project) {
                this.router.navigate(['/']);
            }
        } else {
            // Si l'ID est manquant ou incorrect, rediriger vers la page d'accueil
            this.router.navigate(['/']);
        }
    }
}
