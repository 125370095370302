import { Component } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
    activeLink: string = 'home';

    setActiveLink(link: string) {
        this.activeLink = link;
    }
}
