<footer class="text-white seaport-footer py-3 d-flex align-items-center justify-content-between">
  <div class="footer-title">SEAPORT Engineering</div>
  <div class="d-flex flex-row align-items-center">
    <a class="nav-link text-white" [ngClass]="{ active: activeLink === 'home' }" (click)="setActiveLink('home')" href="#home">{{ 'MENU.HOME' | translate }}</a>
    <span class="separateur"></span>
    <a class="nav-link text-white"  [ngClass]="{ active: activeLink === 'services' }"
       (click)="setActiveLink('services')"
       routerLink="/realisations">{{ 'MENU.REALISATIONS' | translate }}</a>
    <span class="separateur"></span>
    <a class="nav-link text-white" [ngClass]="{ active: activeLink === 'contact' }" (click)="setActiveLink('contact')" href="#contact">{{'MENU.CONTACT' | translate}}</a>
    <span class="separateur"></span>
  </div>
</footer>
