import { Component, OnDestroy, OnInit } from '@angular/core';
import { faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import { Router } from "@angular/router";
import { ProjectService } from "../project.service";
import { Subscription } from "rxjs";
import { TranslateService, LangChangeEvent } from "@ngx-translate/core";

@Component({
    selector: 'app-realisations',
    templateUrl: './realisations.component.html',
    styleUrls: ['./realisations.component.scss']
})
export class RealisationsComponent implements OnInit, OnDestroy {
    faChevronRight = faChevronRight;
    faChevronLeft = faChevronLeft;

    projects: any[] = [];
    filteredProjects: any[] = [];
    currentFilter: string = 'all';
    projectsSubscription: Subscription | null = null;
    langChangeSubscription: Subscription | null = null;

    translatedTypes = {
        all: '',
        fluvial: '',
        maritime: '',
        grand: ''
    };

    constructor(private router: Router, private projectService: ProjectService, private translate: TranslateService) {}

    ngOnInit(): void {
        this.projectsSubscription = this.projectService.getProjects().subscribe(projects => {
            this.projects = projects;
            this.filteredProjects = [...this.projects];
        });

        this.loadTranslations();

        this.langChangeSubscription = this.translate.onLangChange.subscribe((event: LangChangeEvent) => {
            this.loadTranslations();
            this.filterProjects('all');

        });
        this.projectService.loadProjects();
        this.filterProjects('all');
    }

    ngOnDestroy(): void {
        if (this.projectsSubscription) {
            this.projectsSubscription.unsubscribe();
        }
        if (this.langChangeSubscription) {
            this.langChangeSubscription.unsubscribe();
        }
    }

    loadTranslations(): void {
        this.translate.get(['PROJECT.ALL', 'PROJECT.FLUVIAUX', 'PROJECT.MARITIMES', 'PROJECT.GRANDS']).subscribe(translations => {
            this.translatedTypes.all = translations['PROJECT.ALL'];
            this.translatedTypes.fluvial = translations['PROJECT.FLUVIAUX'];
            this.translatedTypes.maritime = translations['PROJECT.MARITIMES'];
            this.translatedTypes.grand = translations['PROJECT.GRANDS'];
        });
    }

    filterProjects(type: string): void {
        this.currentFilter = type;
        if (type === 'all') {
            this.filteredProjects = [...this.projects];
        } else {
            this.filteredProjects = this.projects.filter(project => project.type === type);
        }
    }

    selectProject(project: any): void {
        this.router.navigate(['/project-detail', project.id]);
    }

    getTruncatedTitle(title: string, limit: number): string {
        return title.length > limit ? title.slice(0, limit) + '...' : title;
    }
}
