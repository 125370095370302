<app-menu></app-menu>
<section id="home" class="color-section-2">
    <div class="home-section">
        <img src="../../assets/home-image.png" class="home-container">
        <img src="assets/s800terminal_9.jpg" class="home-box">
        <div class="home-sizing d-flex flex-column gap-3 justify-content-center">
            <div class="home-title home-title-first">{{"HOME.TITLE" | translate}}</div>
            <div class="home-title home-subtitle-1">{{"HOME.SUBTITLE-1" | translate}}</div>
            <div class="home-subtitle mb-4">{{"HOME.SUBTITLE" | translate}}</div>
            <a class="home-button" href="#contact">{{"MENU.CONTACT" | translate}}</a>
        </div>

    </div>
    <div class="d-flex flex-column align-items-center justify-content-center about-section">
        <div class="home-title">{{"HOME.ABOUT" | translate}}</div>
        <div class="home-subtitle-about about-section-subtitle home-subtitle mb-3">{{"HOME.ABOUT-TEXT" | translate}}</div>
    </div>
</section>
<section id="service" class="color-section">
    <app-services></app-services>
</section>
<!-- <section id="realised-projects" class="color-section-2 project-realised-container">
<app-realised-projects></app-realised-projects>
</section> -->
<section id="contact" class="color-section">
    <app-contact></app-contact>
</section>
<div class="content">
    <router-outlet></router-outlet>
</div>
