import { Component } from '@angular/core';
import { TranslateService } from "@ngx-translate/core";
import { Router, NavigationEnd } from "@angular/router";

@Component({
    selector: 'app-menu',
    templateUrl: './menu.component.html',
    styleUrls: ['./menu.component.scss']
})
export class MenuComponent {
    activeLink: string = '';

    constructor(public translate: TranslateService, private router: Router) {
        this.translate.setDefaultLang('fr');
        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.updateActiveLink(event.urlAfterRedirects);
            }
        });
    }

    updateActiveLink(url: string): void {
        if (url === '/') {
            this.activeLink = 'home';
        } else if (url.startsWith('/realisations') || url.startsWith('/project-detail')) {
            this.activeLink = 'services';
        } else if (url.includes('#contact')) {
            this.activeLink = 'contact';
        } else {
            this.activeLink = ''; // Aucun lien actif par défaut
        }
    }

    changeLanguage(event: any) {
        const language = event.target.value;
        this.translate.use(language);
    }

    get currentLanguage(): string {
        return this.translate.currentLang;
    }

    setActiveLink(link: string) {
        this.activeLink = link;
    }

    onLanguageChange(event: Event): void {
        const target = event.target as HTMLSelectElement;
        this.translate.use(target.value);
    }

    goToHome(): void {
        this.router.navigate(['/']);
        this.activeLink = '';
    }

    scrollToContact(): void {
        if (this.router.url !== '/') {
            this.router.navigate(['/']).then(() => {
                this.scrollToContactSection();
            });
        } else {
            this.scrollToContactSection();
        }
        this.activeLink = 'contact';
    }

    private scrollToContactSection(): void {
        setTimeout(() => {
            const contactElement = document.getElementById('contact');
            if (contactElement) {
                contactElement.scrollIntoView({ behavior: 'smooth', block: 'end' });
            }
        }, 100);
    }
}
