import {Component, OnInit} from '@angular/core';
import {TranslateService} from "@ngx-translate/core";
import {NavigationEnd, Router} from "@angular/router";
import {filter} from "rxjs";
import { ViewportScroller } from '@angular/common';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    activeLink: string = 'home';

    constructor(
        private translate: TranslateService,
        private router: Router,
        private viewportScroller: ViewportScroller
    ) {
        this.translate.setDefaultLang('fr');
        this.translate.use(this.getPreferredLanguage());
    }

    ngOnInit(): void {
        this.router.events.pipe(
            filter(event => event instanceof NavigationEnd)
        ).subscribe(() => {
            this.viewportScroller.scrollToPosition([0, 0]);
        });

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this.handleAnchorScroll();
            }
        });
    }

    setActiveLink(link: string): void {
        this.activeLink = link;
    }

    onLanguageChange(event: any): void {
        const lang = event.target.value;
        this.translate.use(lang);
        localStorage.setItem('preferredLanguage', lang);
    }

    goToHome(): void {
        this.router.navigate(['/']);
    }

    getPreferredLanguage(): string {
        return localStorage.getItem('preferredLanguage') || 'fr';
    }

    handleAnchorScroll(): void {
        const tree = this.router.parseUrl(this.router.url);
        if (tree.fragment) {
            const element = document.getElementById(tree.fragment);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
            }
        }
    }
}
