import { Component, OnInit, OnDestroy } from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ProjectService } from '../project.service';

@Component({
    selector: 'app-realised-projects',
    templateUrl: './realised-projects.component.html',
    styleUrls: ['./realised-projects.component.scss']
})
export class RealisedProjectsComponent implements OnInit, OnDestroy {
    faChevronRight = faChevronRight;
    projects: any[] = [];
    projectsSubscription: Subscription | null = null;

    constructor(private router: Router, private projectService: ProjectService) {}

    ngOnInit(): void {
        this.loadProjects(); // Ensure projects are loaded on component init

        this.projectsSubscription = this.projectService.getProjects().subscribe(projects => {
            this.projects = projects;
        });
    }

    ngOnDestroy(): void {
        if (this.projectsSubscription) {
            this.projectsSubscription.unsubscribe();
        }
    }

    selectProject(project: any): void {
        this.router.navigate(['/project-detail', project.id]);
    }

    private loadProjects(): void {
        this.projectService.loadProjects();
    }
}
